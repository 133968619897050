import React from "react"
import Layout from "../components/layout"
import TermsAndConditions from "../components/terminos-y-condiciones/TermsAndConditions"
//data
import pageData from "../data/pageData"

const TerminosCondiciones = ({ location }) => (
  <Layout location={location} >
    <TermsAndConditions title={pageData.termsAndConditions.title} termsData={pageData.termsAndConditions.about} items={pageData.termsAndConditions.items} />
  </Layout>
)

export default TerminosCondiciones